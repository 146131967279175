<template>
  <div class="h-100vh">
    <!-- <div class="logo-image-container text-center login-header mx-3">
      <img src="@/assets/logo-d-dot.svg" alt="Kitten" class="logo-img" />
    </div> -->
    <div class="d-flex justify-content-center w-100 login-container">
      <div class="w-100 my-auto">
        <div class="d-flex justify-content-center">
          <div class="card p-5 mx-3 session-card">
            <div class="text-center f-size-22 font-weight-bold">
              เซสชั่นของคุณหมดเวลาแล้ว
            </div>
            <div class="my-5 text-center">
              กรุณาพิมพ์บริการที่ท่านต้องการใหม่อีกครั้งหรือพิมพ์
              <u class="main-color" @click="sendMessageToChat">ติดต่อแอดมิน</u>
              เพื่อติดต่อเจ้าหน้าที่ค่ะ
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    sendMessageToChat() {
      if (this.$liff.isInClient()) {
        this.$liff
          .sendMessages([
            {
              type: "text",
              text: "ติดต่อแอดมิน"
            }
          ])
          .then(() => {
            console.log("message sent");
          })
          .catch(err => {
            console.log("error", err);
          });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.session-card {
  border-radius: 10px;
}
.login-container {
  height: calc(100vh - 160px);
}
</style>